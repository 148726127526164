import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CometChat } from "@cometchat-pro/chat";


import Translator from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/resources/localization/translator";
import {CometChatContextProvider} from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/CometChatContext";
import {theme} from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/resources/theme";
import * as enums from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/util/enums.js";

import {chatScreenMainStyle, chatScreenStyle, errorStyle, wrapperStyle,} from "./style";
import {COMETCHAT_CONSTANTS} from "../../consts";
import PropTypes from "prop-types";
import TinaChatMessages from "../ChatMessages";

//disable live reaction
enums.CONSTANTS.LIVE_REACTIONS = '';

const widgetSettings = {
    main: {
        'enable_sound_for_calls' : false,
        'enable_sound_for_messages' : false,
        'enable_sending_messages': true,
        'enable_voice_calling': false,
        'hide_join_leave_notifications': true,
    }
}



export default class App extends React.Component {



    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            item: {},
            type: "",
            tab: "conversations",
            groupmessage: {},
            lastmessage: {},
            lang: props.lang,
            unreadMessages: [],
        }

        this.chatListRef = React.createRef();
        this.incomingMessageRef = React.createRef();
        this.contextProviderRef = React.createRef();
    }

    login = (uid) => {
        this.props.onLogin(uid, COMETCHAT_CONSTANTS.AUTH_KEY);
    }

    async componentDidMount() {

        const parsedUrl = new URL(window.location.href);
        const id = parsedUrl.pathname.replace("/", "");

        if (!id) {
            return;
        }

        const assignUserToGroup = async ({userId, groupId}) => {
            const membersList = [
                new CometChat.GroupMember(userId, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT),
            ];
            let addMembersToGroup = await CometChat.addMembersToGroup(groupId, membersList);
            console.log({message: 'successfully added  returner user as group member', addMembersToGroup});
        }

        const createAndAssignUserIfGroupExists = async ({id}) => {
            const group = await CometChat.getGroup(id);
            if (group) {
                console.log({message: 'successfully fetched group with uid', groupId: id});
                const createdUser = await CometChat.createUser({
                    uid: "returner" + Date.now(),
                    "name": "Returner",
                    withAuthToken: true
                }, COMETCHAT_CONSTANTS.AUTH_KEY);
                console.log({message: 'successfully created new returner user', createdUser});
                await assignUserToGroup({userId: createdUser.getUid(), groupId: id});
                return {createdUser, group};
            } else {
                console.log({message: 'group with uid does not exist', groupId: id});
                return {};
            }
        }

        try {
            const loggedInUser = await CometChat.getLoggedinUser();
            if (!loggedInUser) {
                const { createdUser, group } = await this.runAsAdmin(createAndAssignUserIfGroupExists, {id});
                if (createdUser && group) {
                    await CometChat.login(createdUser.getUid(), COMETCHAT_CONSTANTS.AUTH_KEY);
                    console.log({
                        message: 'successfully logged in created returner user',
                        userId: createdUser.getUid()
                    });
                    this.startGroupConversation(group);
                } else {
                    console.log({message: 'failed to create returner user and assign to group'});
                }
            } else {
                const group = await CometChat.getGroup(id);
                if (group) {
                    if (group.getHasJoined()) {
                        console.log({
                            message: 'successfully fetched group with uid',
                            groupId: id
                        });
                        this.startGroupConversation(group);
                    } else {
                        const userId = loggedInUser.getUid();
                        await this.runAsAdmin(assignUserToGroup, {userId, groupId: id});
                        await CometChat.login(userId, COMETCHAT_CONSTANTS.AUTH_KEY);
                        console.log({
                            message: 'successfully logged in returner user',
                            userId: userId
                        });
                        this.startGroupConversation(group);
                    }
                } else {
                    console.log({
                        message: 'group with id does not exist',
                        groupId: id
                    });
                }
            }
        } catch (e) {
            if(e.code === 'AUTH_ERR_AUTH_TOKEN_NOT_FOUND'){
                await CometChat.logout().catch(e => console.log(e));
            }

            if(e.message){
                this.setState({errorMessage: e.message});
            }
            console.log(e);
        }
    }

    async runAsAdmin(asyncOperation, args) {
        try {
            let adminUser = COMETCHAT_CONSTANTS.ADMIN_USER_UID;
            await CometChat.login(adminUser, COMETCHAT_CONSTANTS.AUTH_KEY);
            console.log({message: 'logged in with admin account', userId: adminUser});
            return await asyncOperation(args);
        } finally {
            const result = await CometChat.logout();
            console.log({message: 'logged out admin account', result});
        }
    }



    componentDidUpdate(prevProps) {
        if (prevProps.lang !== this.props.lang) {
            this.setState({lang: this.props.lang});
        }
    }

    startGroupConversation = (item) => {
        this.setState({item: {...item}, type: 'group', unreadMessages : [{}]})
    }

    actionHandler = (action, item, count, ...otherProps) => {

        switch (action) {
            case "messageEdited":
            case "messageDeleted":
                this.updateLastMessage(item[0]);
                break;
            case "unreadMessages":
                this.setState({unreadMessages: [...item]});
                break;
            default:
                break;
        }
    }

    updateLastMessage = (message) => {
        this.setState({lastmessage: message});
    }


    render() {

        let loader = null;
        if (this.props.loading) {
            loader = (<div className="loading">Loading...</div>);
        }

        let errorMessage = null;
        if (this.state.errorMessage) {
            errorMessage = (<p css={errorStyle()}>{this.state.errorMessage}</p>);
        }


        let messageScreen = null;
        if (Object.keys(this.state.item).length) {
            messageScreen = (
                <TinaChatMessages
                    widgetsettings={widgetSettings}
                    viewdetailscreen={false}
                    theme={theme}
                    lang={this.state.lang}
                    _parent="conversations"
                    actionGenerated={this.actionHandler}/>
            );
        }

        return (
            <CometChatContextProvider ref={el => this.contextProviderRef = el} user={this.props.chatWithUser}
                                      group={this.state.item.guid}>
                <div css={wrapperStyle()}>
                    <div css={chatScreenStyle(this.props.theme)} className="cometchat cometchat--chats"
                         dir={Translator.getDirection(this.state.lang)}>
                        {loader}
                        <div css={chatScreenMainStyle(this.state, this.props)}
                             className="chats__main">
                            {errorMessage}
                            {messageScreen}
                        </div>
                    </div>
                </div>
            </CometChatContextProvider>
        );

    }
}

// Specifies the default values for props:
App.defaultProps = {
    lang: Translator.getDefaultLanguage(),
    theme: theme,
    chatWithUser: "",
    chatWithGroup: "bi6f0azr",
};

App.propTypes = {
    lang: PropTypes.string,
    theme: PropTypes.object,
    chatWithUser: PropTypes.string,
    chatWithGroup: PropTypes.string,
}
