export const wrapperStyle = () => {

    return {
        width: "100vw",
        height: "100vh",
    }
}

export const chatScreenMainStyle = (state, props) => {

    const mq = [...props.theme.breakPoints];

    return {
        width: "100%",
        height: "100%",
        order: "2",
        display: "flex",
        flexDirection: "row",
        [`@media ${mq[1]}, ${mq[2]}`]: {
            width: "100%",
        }
    }
}


export const chatScreenStyle = (theme) => {

    return {
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        fontFamily: `${theme.fontFamily}`,
        position: "relative",
        "*": {
            boxSizing: "border-box",
            fontFamily: `${theme.fontFamily}`,
            "::-webkit-scrollbar": {
                width: "8px",
                height: "4px",
            },
            "::-webkit-scrollbar-track": {
                background: "#ffffff00"
            },
            "::-webkit-scrollbar-thumb": {
                background: "#ccc",
                "&:hover": {
                    background: "#aaa"
                }
            }
        }
    }
}

export const errorStyle = () => {

    return {
        fontSize: "12px",
        color: "red",
        width: "100%",
        textAlign: "center",
    }
}
